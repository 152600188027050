import React, { useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

function calcPosTarget(target) {
  const { top, bottom, left } = target.getBoundingClientRect();
  const { offsetWidth } = target;
  const { pageYOffset } = window;

  const tooltipHeight = document.querySelector('.qtip').offsetHeight;
  const reverse = top - tooltipHeight < 0;

  const x = `${left + offsetWidth / 2}px`;
  const y = `${(reverse ? bottom : top) + pageYOffset}px`;

  return {
    left: x,
    top: y,
    reverse,
  };
}
const Tooltip = ({ target, children }) => {
  const [coords, setCoords] = useState({ left: 0, top: 0, reverse: false });

  useLayoutEffect(() => {
    const newCoords = calcPosTarget(target);

    if (JSON.stringify(newCoords) !== JSON.stringify(coords)) {
      setCoords(newCoords);
    }
  }, [target, coords]);

  return createPortal(
    <div
      className={`qtip ${coords.reverse ? 'qtip--reverse' : ''}`}
      style={{ left: coords.left, top: coords.top }}
    >
      <div className="qtip-content">{children}</div>
    </div>,
    document.body
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
