import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import cn from 'classnames';

import './index.scss';

import { Link, Text } from 'components/atoms';

const Breadcrumbs = ({ data, parent, isHorizontal, className }) => {
  const { path: parentPath, title: parentTitle } = parent || {};
  const { path: currentPath, title: currentTitle } = data || {};

  const breadcrumbs = [];
  breadcrumbs.push({
    '@type': 'ListItem',
    position: 1,
    name: 'Home Page',
    item: 'https://ester.co',
  });

  let position = 2;
  if (parent) {
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      name: parentTitle,
      item: 'https://ester.co' + parentPath,
    });
    position = position + 1;
  }
  if (data) {
    breadcrumbs.push({
      '@type': 'ListItem',
      position: position,
      name: currentTitle,
      item: 'https://ester.co' + currentPath,
    });
    position = position + 1;
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbs,
          })}
        </script>
      </Helmet>
      <div
        className={cn(
          'breadcrumbs',
          [className],
          isHorizontal && 'breadcrumbs_horizontal'
        )}
      >
        <div className="breadcrumbs__block">
          <Link href="/">
            <Text tag="span" className="subheader breadcrumbs__text">
              Home Page
            </Text>
          </Link>
          <Text tag="span" className="subheader breadcrumbs__text">
            {'  &#8212; '}
          </Text>
          {parent && (
            <>
              <Link href={parentPath}>
                <Text tag="span" className="subheader breadcrumbs__text">
                  {parentTitle}
                </Text>
              </Link>
              <Text tag="span" className="subheader breadcrumbs__text">
                {'  &#8212; '}
              </Text>
            </>
          )}
          <Text
            tag="span"
            className="subheader breadcrumbs__text breadcrumbs__link_active"
          >
            {currentTitle}
          </Text>
        </div>
      </div>
    </>
  );
};

Breadcrumbs.defaultProps = {
  className: '',
};

Breadcrumbs.propTypes = {
  data: PropTypes.object.isRequired,
  parent: PropTypes.object,
  isHorizontal: PropTypes.bool,
  className: PropTypes.string,
};

export default Breadcrumbs;
