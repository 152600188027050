import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const BlogMeta = props => {
  const { children } = props;

  return (
    <div className="blog-meta">
      {children}
    </div>
  );
};

BlogMeta.defaultProps = {
  className: '',
};

BlogMeta.propTypes = {
  className: PropTypes.string,
};

export default BlogMeta;
