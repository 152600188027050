import { useStaticQuery, graphql } from 'gatsby';

export const useAllPost = () => {
  const { allWordpressPost } = useStaticQuery(
    graphql`
      query AllPost {
        allWordpressPost(sort: { fields: date, order: DESC }) {
          edges {
            node {
              id
              wordpress_id
              title
              path
              date
              categories {
                name
              }
              featured_media {
                title
                alt_text
                source_url
                acf {
                  placeholder_base64
                }
                ...MediaDetailsFragment
              }
            }
          }
        }
      }
    `
  );

  return allWordpressPost.edges;
};
