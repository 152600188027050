import { useStaticQuery, graphql } from 'gatsby';

export const useOptions = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query contactEmail {
        wordpressAcfOptions {
          options {
            contact_email
            featured_works_number
          }
        }
      }
    `
  );

  return wordpressAcfOptions;
};
