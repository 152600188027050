import { useStaticQuery, graphql } from 'gatsby';

export const useAllTeamMember = () => {
  const { allWordpressWpAppTeamMember } = useStaticQuery(
    graphql`
      query allTeamMember {
        allWordpressWpAppTeamMember {
          edges {
            node {
              title
              wordpress_id
              acf {
                angellist_url
                linkedin_url
                position
              }
              featured_media {
                alt_text
                source_url
                title
                acf {
                  placeholder_base64
                }
                ...MediaDetailsFragment
              }
            }
          }
        }
      }
    `
  );

  return allWordpressWpAppTeamMember.edges;
};
