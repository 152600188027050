import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Container = ({ children, type, className }) => {
  const typesClasses = {
    default: 'container-narrow',
    fluid: 'container-wide',
  };
  return <div className={cn(typesClasses[type], [className])}>{children}</div>;
};

Container.defaultProps = {
  type: 'default',
  className: '',
};

Container.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Container;
