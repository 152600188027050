import { useStaticQuery, graphql } from 'gatsby';

export const useOffices = () => {
  const { allWordpressWpAppOffices } = useStaticQuery(
    graphql`
      query allOffices {
        allWordpressWpAppOffices(sort: { fields: menu_order, order: ASC }) {
          edges {
            node {
              acf {
                address
                country
              }
              menu_order
            }
          }
        }
      }
    `
  );

  return allWordpressWpAppOffices.edges;
};
