import React from 'react';
import PropTypes from 'prop-types';
import GatsbyLink from 'gatsby-link';
import cn from 'classnames';

import './index.scss';

const Link = ({
  children,
  className = '',
  href,
  showArrow = false,
  isRouter = true,
  activeClassName = '',
  partiallyActive,
  ...rest
}) => {
  const classNames = cn(
    { link: !className.includes('btn') },
    { 'link--arrow': showArrow },
    className
  );

  return (
    <>
      {isRouter ? (
        <GatsbyLink
          to={href}
          className={classNames}
          activeClassName={activeClassName}
          partiallyActive
          {...rest}
        >
          {children}
        </GatsbyLink>
      ) : (
        <a href={href} className={classNames} {...rest}>
          {children}
        </a>
      )}
    </>
  );
};

Link.propTypes = {
  isRouter: PropTypes.bool,
  children: PropTypes.object,
  href: PropTypes.string.isRequired,
  showArrow: PropTypes.bool,
  className: PropTypes.string,
};

export default Link;
