import { useStaticQuery, graphql } from 'gatsby';

export const useFooterLocations = () => {
  const { allWordpressWpApiMenusMenuLocations } = useStaticQuery(
    graphql`
      query allMenuLocations {
        allWordpressWpApiMenusMenuLocations {
          edges {
            node {
              label
              wordpress_id
              menu_id
            }
          }
        }
      }
    `
  );

  return allWordpressWpApiMenusMenuLocations.edges;
};
