const IGNORE_LIST = ['full', 'dummy', 'placeholder'];

// todo: create the return value of `sizes`
export const getMediaDetails = mediaDetails => {
  const { sizes, width } = mediaDetails;

  if (!mediaDetails || !sizes) return null;

  const { full } = sizes;

  // filter the existing values
  const filtered = Object.fromEntries(
    Object.entries(sizes).filter(
      ([key, value]) => !IGNORE_LIST.includes(key) && value
    )
  );

  if (!Object.keys(filtered).length) return null;

  // create the biggest image
  const fullSize = Object.fromEntries(
    Object.entries(Object.assign({ width }, full)).filter(([key]) =>
      ['width', 'source_url'].includes(key)
    )
  );

  // create new values
  const maped = Object.values(filtered)
    .map(({ source_url }) => {
      const resolution = source_url
        .split('-')
        .reverse()[0]
        .split('.')[0];
      return {
        resolution,
        width: Number(resolution.split('x')[0]),
        source_url,
      };
    })
    .sort((a, b) => a.width - b.width);

  maped.push(fullSize);

  // generate the srcset
  const srcSet = maped
    .map(({ width, source_url }) => `${source_url} ${width}w`)
    .join(', ');

  return { src: fullSize.source_url, srcSet };
};
