import { useAllWork } from './useAllWork';
import { useAllCaseStudy } from './useAllCaseStudy';

export const useAllCaseStudyWorkArray = () => {
  const works = useAllWork();
  const caseStudies = useAllCaseStudy();

  const mergedList = works.concat(caseStudies);

  mergedList.sort((a, b) => {
    return a.node.menu_order - b.node.menu_order;
  });

  return mergedList.map(item => item.node);
};
