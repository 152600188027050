/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, meta, yoastMeta, url }) {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query {
        wordpressAcfOptions {
          options {
            seo_social {
              image {
                source_url
              }
            }
          }
        }
      }
    `
  );

  /**
   * Handle robots meta
   */

  let indexable = true;

  // if ((yoastMeta.yoast_wpseo_title || '').includes('Industries')) {
  //   indexable = false;
  // }

  let robots = meta.find(m => m.name === 'robots');
  if (!robots) {
    robots = { name: 'robots' };
    meta.push(robots);
  }
  robots.content = (indexable ? 'index,follow' : 'noindex,nofollow');

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={yoastMeta.yoast_wpseo_title}
        titleTemplate={`%s`}
        meta={[
          {
            name: 'title',
            content: yoastMeta.yoast_wpseo_title,
          },
          {
            name: 'description',
            content: yoastMeta.yoast_wpseo_metadesc,
          },

          {
            name: 'og:type',
            content: 'website',
          },
          {
            name: 'og:url',
            content: yoastMeta.yoast_wpseo_canonical,
          },
          {
            name: 'og:title',
            content: yoastMeta.yoast_wpseo_facebook_title || yoastMeta.yoast_wpseo_title,
          },
          {
            name: 'og:description',
            content: yoastMeta.yoast_wpseo_facebook_description || yoastMeta.yoast_wpseo_metadesc,
          },
          {
            name: 'og:image',
            content: wordpressAcfOptions.options.seo_social.image.source_url,
          },

          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:url',
            content: yoastMeta.yoast_wpseo_canonical,
          },
          {
            name: 'twitter:title',
            content: yoastMeta.yoast_wpseo_twitter_title || yoastMeta.yoast_wpseo_title,
          },
          {
            name: 'twitter:description',
            content: yoastMeta.yoast_wpseo_twitter_description || yoastMeta.yoast_wpseo_metadesc,
          },
          {
            name: 'twitter:image',
            content: wordpressAcfOptions.options.seo_social.image.source_url,
          },

          // Bing Webmasters validation
          {
            name: 'msvalidate.01',
            content: '57516E3D49347826F6D306325F731269',
          },

          {
            name: 'twitter:url',
            content: yoastMeta.yoast_wpseo_canonical,
          },
          {
            name: 'twitter:title',
            content: yoastMeta.yoast_wpseo_twitter_title || yoastMeta.yoast_wpseo_title,
          },
          {
            name: 'twitter:description',
            content: yoastMeta.yoast_wpseo_twitter_description || yoastMeta.yoast_wpseo_metadesc,
          },
          {
            name: 'twitter:image',
            content: wordpressAcfOptions.options.seo_social.image.source_url,
          },
        ].concat(meta)}
      />
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=rMJ7RGMnvK"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=rMJ7RGMnvK"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=rMJ7RGMnvK"/>
        <link rel="manifest" href="/site.webmanifest?v=rMJ7RGMnvK"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg?v=rMJ7RGMnvK" color="#302b44"/>
        <link rel="shortcut icon" href="/favicon.ico?v=rMJ7RGMnvK"/>
        <meta name="msapplication-TileColor" content="#ff0000"/>
        <meta name="msapplication-TileImage" content="/mstile-144x144.png?v=rMJ7RGMnvK"/>
        <meta name="theme-color" content="#ffffff"/>

        <link rel="alternate" href={url} hreflang="en-us" />
        <link rel="alternate" href={url} hreflang="en-GB" />
      </Helmet>
    </React.Fragment>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  yoastMeta: {},
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  yoastMeta: PropTypes.object,
};

export default SEO;
