import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClose: false,
      onClose: props.onClose,
      scrollTop: window.pageYOffset,
      windowWidth: window.innerWidth,
    };

    this.closePopup = this.closePopup.bind(this);
    this.keyupHandler = this.keyupHandler.bind(this);

    this.signEvent();
    this.fixedWindow();
  }

  fixedWindow() {
    const { body } = document;
    const { windowWidth } = this.state;
    const paddingOffset = windowWidth - body.offsetWidth;
    body.style.paddingRight = `${paddingOffset}px`;
    body.classList.add('tingle-enable');
  }

  closePopup({ target }, isKeyUp) {
    const { isClose } = this.state;

    if (isClose) return;

    if (!isKeyUp) {
      const { iscloseButton } = target.dataset;

      if (!iscloseButton) return;
    }

    const { body } = document;
    const { onClose } = this.props;
    body.style.paddingRight = 0;
    body.classList.remove('tingle-enable');

    this.setState(
      {
        isClose: true,
      },
      () => {
        this.scrollWindow();
        onClose();
      }
    );
  }

  keyupHandler({ keyCode }) {
    const ESC = 27;
    if (keyCode === ESC) {
      this.closePopup({}, true);
    }
  }

  signEvent() {
    document.addEventListener('keyup', this.keyupHandler);
  }

  unsubscribeEvent() {
    document.removeEventListener('keyup', this.keyupHandler);
  }

  scrollWindow() {
    const { scrollTop } = this.state;

    if (window.pageYOffset !== scrollTop) {
      window.scrollBy(0, scrollTop);
    }
  }

  componentWillUnmount() {
    this.unsubscribeEvent();
  }

  render() {
    const { isClose } = this.state;
    const { children, className } = this.props;

    if (isClose) {
      this.unsubscribeEvent();
      return null;
    } else {
      this.signEvent();
    }

    return createPortal(
      <div
        className={cn(
          'tingle-modal',
          'dontgo-popup',
          'tingle-modal--visible',
          'tingle-modal--overflow',
          [className]
        )}
        onClick={this.closePopup}
        onKeyDown={this.closePopup}
        data-isclose-button={true}
        role="button"
        aria-label="popup"
        tabIndex={0}
      >
        <div
          className={cn('tingle-modal-box', className && `${className}-box`)}
        >
          <div
            className={cn(
              'tingle-modal-box__content',
              className && `${className}-box__content`
            )}
          >
            <span
              onClick={this.closePopup}
              onKeyDown={this.closePopup}
              className={cn(
                'dontgo-popup__close icon-modal-close',
                className && `${className}-close`
              )}
              data-isclose-button={true}
              role="button"
              aria-label='popup'
              tabIndex={0}
            />
            {children}
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

Popup.defaultProps = {
  onClose: () => null,
};

Popup.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default Popup;
