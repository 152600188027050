import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Row = props => {
  const { children, align, className } = props;

  return <div className={cn('row', [align], [className])}>{children}</div>;
};

Row.defaultProps = {
  align: '',
  className: '',
};

Row.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
};

export default Row;
