import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import cn from 'classnames';

import './index.scss';

import LogoSrc from 'images/logo.svg';

import {
  Container,
  Row,
  Column,
  Link,
  Button,
  Text,
  Icon,
} from 'components/atoms';

import { useMainMenu } from 'graphql/hooks';

const addOpenMenuClasses = menuIsOpen => {
  if (menuIsOpen) {
    document.documentElement.classList.add('hide-scroll');
    document.body.classList.add('hide-scroll');
    if (document.querySelector('.footer')) {
      document.querySelector('.footer').classList.add('footer_active');
    } else {
      document
        .querySelector('.footer-full')
        .classList.add('footer-full_active');
    }
  } else {
    document.documentElement.classList.remove('hide-scroll');
    document.body.classList.remove('hide-scroll');
    if (document.querySelector('.footer')) {
      document.querySelector('.footer').classList.remove('footer_active');
    } else if (document.querySelector('.footer-full')) {
      document
        .querySelector('.footer-full')
        .classList.remove('footer-full_active');
    }
  }
};

const Header = ({ headerInverted }) => {
  const { items } = useMainMenu();

  const mobileMenuIcons = useStaticQuery(
    graphql`
      query {
        allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "footer-menu-2" } }
        ) {
          edges {
            node {
              name
              id
              slug
              items {
                title
                target
                url
                wordpress_id
                classes
              }
            }
          }
        }
      }
    `
  );

  const {
    allWordpressWpApiMenusMenusItems: { edges },
  } = mobileMenuIcons;

  const iconLinksList = edges.map(({ node }, index) => {
    const { items } = node;

    return (
      <React.Fragment key={index}>
        {Array.from(items)
          .reverse()
          .map(({ title, target, url, classes, rel }, index) => {
            return (
              <li
                className="menu-main__list-item menu-main__list_icons-item"
                key={index}
              >
                <Link
                  href={url}
                  isRouter={false}
                  target={target || null}
                  rel={rel || null}
                >
                  <Icon
                    variant={title.toLowerCase()}
                    className={cn({
                      [`${classes}_white`]: headerInverted,
                    })}
                  />
                </Link>
              </li>
            );
          })}
      </React.Fragment>
    );
  });

  const [isShow, changeShow] = useState(false);

  const showMobileMenu = () => {
    changeShow(!isShow);
  };

  useEffect(() => {
    addOpenMenuClasses(isShow);
  }, [isShow]);

  const links = items.map(
    ({ title, classes, object_slug, wordpress_children }, index) => {
      const isButton = classes === 'btn';
      let subMenu = null;
      if (wordpress_children) {
        subMenu = wordpress_children.map(
          ({ title, wordpress_children: subLinks }, menuIndex) => {
            const subMenuTitle = title !== '-' && title;

            if (!subLinks) return null;
            const subMenuLinks = subLinks.map(
              ({ title: linkTitle, url }, index) => {
                const correctLink = url
                  .replace('https://', '')
                  .replace(/(.*?)\//, '');
                return (
                  <li
                    key={index}
                    className={cn('menu-main__list-item__submenu-item__link')}
                  >
                    <Link href={`/${correctLink}`}>
                      <Text>{linkTitle}</Text>
                    </Link>
                  </li>
                );
              }
            );

            return (
              <div
                className={cn('menu-main__list-item__submenu-item')}
                key={menuIndex}
              >
                {subMenuTitle && (
                  <Text tag="span" className="subheader">
                    {subMenuTitle}
                  </Text>
                )}
                <ul>{subMenuLinks}</ul>
              </div>
            );
          }
        );
      }

      return (
        <li
          className={cn(
            'menu-main__list-item',
            wordpress_children && 'menu-main__list-item--submenu-hover'
          )}
          key={index}
        >
          <Link
            href={`/${object_slug}`}
            className={cn('menu-main__list-item__link', [classes], {
              'btn_dark-purple': isButton && !headerInverted,
              'btn_transparent-green': isButton && headerInverted,
              'menu-main__list-item__link--disable-hover': wordpress_children,
            })}
            activeClassName="menu-main__list-item__link_active"
            partiallyActive={true}
            isRouter={object_slug !== 'blog'}
          >
            <Text
              tag="span"
              color={cn({
                'dark-purple': !headerInverted,
                'transparent-green': headerInverted,
              })}
              className="subheader"
            >
              {title}
            </Text>
          </Link>
          {wordpress_children && <Icon variant={'menu-arrow'} />}
          {wordpress_children && (
            <div className={cn('menu-main__list-item__submenu')}>{subMenu}</div>
          )}
        </li>
      );
    }
  );

  // const languageLinkData = {
  //   link: '/de',
  //   title: 'Auf Deutsch',
  // };

  // const languageLink = (
  //   <li
  //     className={cn('menu-main__list-item menu-main__list-item_language', {
  //       'menu-main__list-item_language_white': headerInverted,
  //     })}
  //   >
  //     <Link
  //       href={languageLinkData.link}
  //       className="menu-main__list-item__link menu-main__list-item__link_language"
  //     >
  //       <Text
  //         tag="span"
  //         color={cn({
  //           'dark-purple': !headerInverted,
  //           'transparent-green': headerInverted,
  //         })}
  //         className="subheader"
  //       >
  //         {languageLinkData.title}
  //       </Text>
  //     </Link>
  //   </li>
  // );

  return (
    <header
      className={cn(
        'header',
        { header_white: headerInverted },
        { header_active: isShow }
      )}
    >
      <Container type="fluid">
        <Row>
          <Column className="header__container">
            <div className="header__logo">
              <Link href="/">
                <LogoSrc className="logo" />
              </Link>
            </div>

            <Button variant="menu" title="Menu" onClick={showMobileMenu} />

            <nav
              className={cn('menu-main menu-main_header', {
                'menu-main_header_dark': headerInverted,
              })}
            >
              <ul className="menu-main__list menu-main__list_header">
                {links}
                {/* languageLink */}
              </ul>
              <ul className="menu-main__list menu-main__list_icons">
                {iconLinksList}
              </ul>
            </nav>
          </Column>
        </Row>
      </Container>
    </header>
  );
};

Header.defaultProps = {
  headerInverted: false,
};

Header.propTypes = {
  headerInverted: PropTypes.bool,
};

export default Header;
