import { useStaticQuery, graphql } from 'gatsby';

export const useFooterMenu = () => {
  const data = useStaticQuery(
    graphql`
      query FooterMenu {
        allWordpressWpApiMenusMenusItems(
          filter: { slug: { in: ["footer-menu-1", "social"] } }
        ) {
          edges {
            node {
              name
              id
              slug
              items {
                title
                target
                url
                wordpress_id
                classes
                rel: xfn
              }
            }
          }
        }
      }
    `
  );

  return data;
};
