import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Input = React.forwardRef((props, ref) => {
  const {
    className,
    type,
    placeholder,
    isRequired,
    isTextArea,
    activePlaceholder,
    altPlaceholder,
  } = props;
  const [value, setValue] = useState('');
  const [validationState, setValidationState] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (value !== '') {
      setValidationState(true);
      if (type === 'email') {
        if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
          setValidationState(true);
        } else {
          setValidationState(false);
        }
      }
    } else {
      setValidationState(false);
    }
  });

  useEffect(() => {
    setValidationState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <label
      className={cn(
        `input-container`,
        {
          'input-container_invalid': !validationState && isRequired,
        },
        {
          'input-container_invalid-email':
            !validationState && type === 'email' && value && isRequired,
        },
        [className]
      )}
    >
      {isTextArea ? (
        <textarea
          className={cn('textarea')}
          type={type}
          value={value}
          onChange={event => setValue(event.target.value)}
          ref={ref}
        />
      ) : (
        <input
          className={cn('input')}
          type={type}
          value={value}
          onChange={event => setValue(event.target.value)}
          ref={ref}
        />
      )}

      <div
        className={cn(
          `input-placeholder`,
          `${className}-placeholder`,
          activePlaceholder
            ? {
                'input-placeholder_active': value !== '',
              }
            : { 'input-placeholder_disabled': value !== '' }
        )}
      >
        {activePlaceholder
          ? value !== '' && altPlaceholder
            ? altPlaceholder
            : placeholder
          : placeholder}
        {isRequired && value === '' && (
          <span className="placeholder-star">*</span>
        )}
      </div>
    </label>
  );
});

Input.defaultProps = {
  className: '',
  type: 'text',
  isRequired: false,
  isTextArea: false,
  activePlaceholder: false,
};

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  isTextArea: PropTypes.bool,
  activePlaceholder: PropTypes.bool,
  altPlaceholder: PropTypes.string,
};

export default Input;
