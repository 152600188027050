import { useStaticQuery, graphql } from 'gatsby';

export const useAllWork = () => {
  const { allWordpressWpAppWork } = useStaticQuery(
    graphql`
      query AllWork {
        allWordpressWpAppWork(
          sort: { fields: menu_order, order: ASC }
          filter: { slug: { ne: "placeholder" } }
        ) {
          edges {
            node {
              id
              wordpress_id
              title
              path
              link
              slug
              menu_order
              featured_media {
                title
                alt_text
                source_url
                acf {
                  placeholder_base64
                }
                ...MediaDetailsFragment
              }
              acf {
                use_default_featured_image_on_mobile
                featured_image_mobile {
                  title
                  alt_text
                  source_url
                  acf {
                    placeholder_base64
                  }
                }
                description
                excerpt
                logo {
                  url {
                    source_url
                    ...MediaDetailsFragment
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return allWordpressWpAppWork.edges;
};
