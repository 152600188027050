import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Section = props => {
  const { children, className, ...sectionProps } = props;

  return (
    <section className={cn([className])} {...sectionProps}>
      {children}
    </section>
  );
};

Section.defaultProps = {
  className: '',
};

Section.propTypes = {
  className: PropTypes.string,
};

export default Section;
