import React, { useState, useRef } from 'react';
import cn from 'classnames';

import './index.scss';

import ClutchLogo from 'images/clutch-logo.svg';
import ClutchTicker from 'images/clutch-ticker.svg';

const RollingClutch = () => {
  let timer = null;
  let roll = null;
  const [rollStart, setRollStart] = useState(0);
  const timerStep = 50;
  const rollStep = 360 / 100;
  const [rolling, setRolling] = useState(false);
  const ticker = useRef(null);
  const [isTablet, setIsTablet] = useState(false);

  const enableRoll = () => {
    setIsTablet(!!('ontouchstart' in window));
    setRolling(true);
  };

  if (rolling && !isTablet) {
    roll = rollStart;
    timer = setInterval(() => {
      roll += rollStep;
      ticker.current.style.transform = `translate(-50%, -50%) rotate(-${roll}deg)`;
    }, timerStep);
  }

  const disableRoll = () => {
    setRollStart(roll);
    clearInterval(timer);
    setRolling(false);
  };

  return (
    <div className="rolling-clutch">
      <div
        className="rolling-clutch__container"
        onMouseEnter={enableRoll}
        onMouseLeave={disableRoll}
        role="button"
        tabIndex={0}
      >
        <a
          className="rolling-clutch__link"
          href="https://clutch.co/profile/ester-digital"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          {''}
        </a>
        <div className="rolling-clutch__logo">
          <ClutchLogo />
        </div>
        <div
          className={cn(
            'rolling-clutch__ticker',
            isTablet && 'rolling-clutch__ticker--disable'
          )}
          ref={ticker}
        >
          <ClutchTicker />
        </div>
      </div>
    </div>
  );
};

export default RollingClutch;
