export const getHost = () => {
  if (
    typeof window !== 'undefined' &&
    window.location.hostname === 'ester.co'
    ) {
    return 'https://production.admin.home.estr.xyz';
  }

  return 'https://admin.home.estr.xyz';
}
