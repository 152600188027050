import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Button = props => {
  const { title, variant, size, className, refName, ...buttonProps } = props;

  // One of three styles. variant = purple, pink, inline, menu
  const variantClasses = {
    dark: 'btn_dark-purple',
    light: 'btn_transparent-green',
    inline: 'btn_inline',
    menu: 'header__menu-btn',
  };

  // Sizes Available. size = auto, medium, fullWidth
  const sizeClasses = {
    auto: 'btn_auto',
    medium: 'btn_meduim',
    fullWidth: 'btn_full',
  };

  const buttonClasses =
    // in case when props.variant is menu - dont show other classes
    variant === 'menu'
      ? variantClasses[variant]
      : cn('btn', variantClasses[variant], sizeClasses[size], [className]);

  return (
    <button className={buttonClasses} ref={refName} {...buttonProps}>
      <span>{title}</span>
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  variant: 'dark',
  size: '',
  refName: null,
  className: '',
};

Button.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  refName: PropTypes.object,
  className: PropTypes.string,
};

export default Button;
