import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Column = props => {
  const {
    children,
    xl,
    lg,
    md,
    sm,
    col,
    refName,
    className,
    ...columnProps
  } = props;

  const xlDefaultClass = `col-xl-${xl}`;
  const lgDefaultClass = `col-lg-${lg}`;
  const mdDefaultClass = `col-md-${md}`;
  const smDefaultClass = `col-sm-${sm}`;
  const colDefaultClass = `col-${col}`;

  return (
    <div
      className={cn(
        { [xlDefaultClass]: xl },
        { [lgDefaultClass]: lg },
        { [mdDefaultClass]: md },
        { [smDefaultClass]: sm },
        { [colDefaultClass]: col },
        [className]
      )}
      ref={refName}
      {...columnProps}
    >
      {children}
    </div>
  );
};

Column.defaultProps = {
  xl: '',
  lg: '',
  md: '',
  sm: '',
  col: '12',
  refName: null,
  className: '',
};

Column.propTypes = {
  xl: PropTypes.string,
  lg: PropTypes.string,
  md: PropTypes.string,
  sm: PropTypes.string,
  col: PropTypes.string.isRequired,
  refName: PropTypes.object,
  className: PropTypes.string,
};

export default Column;
