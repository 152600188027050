import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import '../../../styles/index.scss';
import './index.scss';

import Header from 'components/shared/Header';

const CookiesPopup = loadable(() => import('components/organisms/CookiesPopup'));

const Layout = ({
  children,
  className,
  isInverted,
  pageWidgetsData,
  invertFooter,
  footerType,
  footerFullInverted,
}) => {
  const components = [];

  switch (footerType) {
    case 'full':
      const FooterFull = loadable(() => import('components/organisms/FooterFull'));
      components.push(<FooterFull footerFullInverted={footerFullInverted} />);
      break;
    case 'compact':
    default:
      const Footer = loadable(() => import('components/shared/Footer'));
      components.push(<Footer
        footerInverted={invertFooter ? !isInverted : isInverted}
        pageWidgetsData={pageWidgetsData}
      />);
      break;
  }

  return (
    <>
      <Header headerInverted={isInverted} />
      <main className={className}>{children}</main>
      {components}
      <CookiesPopup />
    </>
  );
};

Layout.defaultProps = {
  className: '',
  isInverted: false,
  invertFooter: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isInverted: PropTypes.bool,
  invertFooter: PropTypes.bool,
  footerType: PropTypes.string,
};

export default Layout;
