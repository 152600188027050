import { useStaticQuery, graphql } from 'gatsby';

export const useFooterItems = () => {
  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query allMenuItems {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              id
              items {
                title
                url
              }
              wordpress_id
              name
            }
          }
        }
      }
    `
  );

  return allWordpressWpApiMenusMenusItems.edges;
};
