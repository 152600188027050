import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';

import './index.scss';

import { Link, Text } from 'components/atoms';

const scrollToSection = ({ ...params }) => {
  const { e, anchor } = params;

  smoothscroll.polyfill();
  e.preventDefault();

  const hrefTarget = anchor[0] === '#' ? anchor.slice(1) : anchor;
  const sectionTarget = document.getElementById(hrefTarget);

  if (hrefTarget && sectionTarget !== null) {
    const style = window.getComputedStyle(sectionTarget);
    const marginTop = +style.marginTop.replace('px', '');

    window.scrollTo({
      top: sectionTarget.offsetTop - marginTop,
      behavior: 'smooth',
    });

    return;
  }
};

const ScrollTo = props => {
  const { title, className, anchor } = props;

  return (
    <Link
      className={cn([className], 'scroll-to')}
      onClick={e => scrollToSection({ e, anchor })}
      href={anchor}
      isRouter={false}
    >
      <Text tag="span" className="subheader">
        {title}
      </Text>
    </Link>
  );
};

ScrollTo.defaultProps = {
  className: '',
};

ScrollTo.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  anchor: PropTypes.string,
};

export default ScrollTo;
