import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const TabBarItem = ({
  children,
  label,
  activeTab,
  className,
  ...tabBarItemProps
}) => {
  const tabBarItemClasses = cn(
    'tab-bar__item',
    {
      'tab-bar__item_active': activeTab === label && !className,
    },
    [className],
    {
      [`${className}_active`]: activeTab === label && className,
    }
  );

  return (
    <div className={tabBarItemClasses} {...tabBarItemProps}>
      {children}
    </div>
  );
};

TabBarItem.defaultProps = {
  children: null,
  activeTab: '',
  className: '',
};

TabBarItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  activeTab: PropTypes.string,
  className: PropTypes.string,
};

export default TabBarItem;
