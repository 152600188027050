import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Text = props => {
  const { children, type, color, className, tag } = props;

  // type = large, medium, small
  const typeClasses = {
    large: 'type_large',
    medium: 'type_medium',
    small: 'type_small',
  };

  // colors
  const colorClasses = {
    'light-green': 'color_light-green',
    pink: 'color_pink',
    violet: 'color_violet',
    'medium-purple': 'color_medium-purple',
    black: 'color_black',
    'dark-purple': 'color_dark-purple',
    'transparent-green': 'color_transparent-green',
    lavender: 'color_lavender',
  };

  const Tag = tag;

  return (
    <Tag
      className={cn(typeClasses[type], colorClasses[color], [className])}
      dangerouslySetInnerHTML={{ __html: children }}
    ></Tag>
  );
};

Text.defaultProps = {
  type: 'medium',
  color: 'dark-purple',
  animationType: 'reveal',
  className: '',
  tag: 'p',
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  animationType: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.string,
};

export default Text;
