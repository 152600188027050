import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Video = props => {
  const { controls, src, className, loop, autoplay, muted, ...rest } = props;

  const hasControls = controls ? 'controls' : '';
  const isAutoplay = autoplay ? 'autoplay' : '';
  const isLoop = loop ? 'loop' : '';
  const isMuted = muted ? 'muted' : '';

  return (
    <video
      controls={hasControls}
      className={className}
      loop={isLoop}
      autoPlay={isAutoplay}
      muted={isMuted}
      playsInline={true}
      {...rest}
    >
      <source src={src} />
      Video not supported.
      <track src="" kind="captions" srcLang="en" label="captions"></track>
    </video>
  );
};

Video.defaultProps = {
  className: '',
  controls: true,
  loop: true,
  autoplay: false,
  muted: false,
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  muted: PropTypes.bool,
};

export default Video;
