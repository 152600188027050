import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Icon = props => {
  const { variant, className } = props;
  return <i className={cn(`icon-${variant}`, [className])} />;
};

Icon.defaultProps = {
  variant: '',
  className: '',
};

Icon.propTypes = {
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Icon;
