import { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { DEBOUNCE } from 'constants/debounce';

export const useWindowSize = () => {
  let innerWidth = 0;

  if (typeof window !== 'undefined') {
    innerWidth = window.innerWidth;
  }

  const [size, setSize] = useState(innerWidth);
  useLayoutEffect(() => {
    const updateSize = debounce(
      () => setSize(window.innerWidth),
      DEBOUNCE[250]
    );
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  });
  return size;
};
