import { useStaticQuery, graphql } from 'gatsby';

export const useMainMenu = () => {
  const { allWordpressWpApiMenusMenusItems } = useStaticQuery(
    graphql`
      query MainMenu {
        allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main" } }) {
          edges {
            node {
              name
              id
              slug
              items {
                title
                classes
                url
                wordpress_id
                object_slug
                rel: xfn
                wordpress_children {
                  title
                  wordpress_children {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return allWordpressWpApiMenusMenusItems.edges[0].node;
};
