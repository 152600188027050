import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { PLACEHOLDER_64_DEFAULT } from 'constants/placeholders';

import { getMediaDetails } from 'utils/getMediaDetails';

const IMAGE_FIT_TYPES = ['cover', 'fill', 'contain'];

const Image = memo(
  ({
    src: originSrc,
    alt,
    title,
    className,
    placeholder64,
    lazyLoading,
    imageFit,
    imageAspect,
    image,
    onLoad,
    ...rest
  }) => {
    const { src, srcSet } = getMediaDetails(image) || {
      src: originSrc,
      srcSet: `${originSrc} 100w`,
    };

    const lazyLoadingData = lazyLoading
      ? {
          src,
          srcSet,
          base64: placeholder64,
          sizes: '100vw',
          // media: 'screen',
          aspectRatio: imageAspect,
        }
      : '';

    return (
      <>
        {lazyLoadingData ? (
          <Img
            fluid={lazyLoadingData}
            imgStyle={{
              objectFit: imageFit,
              transition: 'opacity 1s ease 0s',
            }}
            loading={'lazy'}
            alt={alt}
            title={title}
            className={className}
            onLoad={onLoad}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            src={originSrc}
            srcSet={srcSet}
            alt={alt}
            title={title}
            className={className}
            onLoad={onLoad}
            {...rest}
          />
        )}
      </>
    );
  }
);

Image.defaultProps = {
  alt: '',
  title: '',
  className: null,
  placeholder64: PLACEHOLDER_64_DEFAULT,
  imageFit: 'contain',
  imageAspect: 1,
  image: {},
  onLoad: null,
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  placeholder64: PropTypes.string,
  lazyLoading: PropTypes.bool,
  imageFit: PropTypes.oneOfType(IMAGE_FIT_TYPES),
  imageAspect: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  image: PropTypes.object, // todo: define the prop types
  onLoad: PropTypes.func,
};

export default Image;
